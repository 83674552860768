import { Box, Container, Divider, Typography } from "@mui/material";
import React from "react";
import Footer from "../Footer/Footer";
import Slider from "./Dinner/Carousel";
import Swiperr from "./Events/Swiper";
import { HeadingTypography } from "./StyledComponet";
import ScrollArrow from "../ScrollArrow/ScrollArrow";

const QuhDock_life = () => {
  return (
    <Box>
      <Container>
        <Box>
          {/* <HeadingTypography variant="h4">
            Histroy Of QUH Dock
          </HeadingTypography>
          <Box sx={{ my: "3%", textAlign: "center" }}>
            <Box sx={{with: {md : "50%", sm : "100%"}, height:{md: "500px", sm: "400px"}}}>
          <iframe title='vedeo' src="https://drive.google.com/file/d/13y5MmA4QXl2dKhYLf287nb6ixHRGSfMX/preview" 
          width="100%"  height= "100%"
          allow="autoplay"/>
           </Box>
          </Box> */}
          {/* Company Events */}
          <Box>
            <HeadingTypography variant="h4">Company Events</HeadingTypography>
            <Swiperr />
          </Box>
          <Divider />

          {/* Company Dinners */}
          <Box>
            <HeadingTypography variant="h4">Company Dinners</HeadingTypography>
            <Slider />
          </Box>
        </Box>
      <ScrollArrow />
      </Container>
      <Footer />
    </Box>
  );
};

export default QuhDock_life;

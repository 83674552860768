import butt_pic6 from '../../../assets/Dinners/butt_karahi/butt_pic6.jpg';
import habibi_pic1 from '../../../assets/Dinners/Habibi_resturant/habibi_pic1.jpg'
import monal_pic7 from '../../../assets/Dinners/Monal_resturant/monal_pic7.jpeg'
import dinner1 from '../../../assets/Dinners/dinner-1.jpeg'
import dinner2 from '../../../assets/Dinners/dinner-2022.jpeg'

import {  ButtKarahi, Habibi, Monal } from './DinnersArray';

 
export const Images = [
  {
    id:1,
    img:butt_pic6,
    date:'Date: 21th march 2022',
    place:'Location: Butt karahi',
    array: ButtKarahi
  },
  {
    id:3,
    img:monal_pic7,
    date:'Date: april  2022',
    place:'Location: Monal(Lahore)',
    array:Monal
  },
  {
    id:2,
    img:habibi_pic1,
    date:'Date: 21th march 2022',
    place:'Location: Habibi(Lahore) ',
    array: Habibi
  },
 
  {
    id:4,
    img:dinner1,
    date:'Date: 21th march 2022',
    place:'Location: Butt karahi',
    array: ButtKarahi
  },
  {
    id:5,
    img:dinner2,
    date:'Date: 21th march 2022',
    place:'Location: Butt karahi',
    array:Monal
  },
]





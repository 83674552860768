import "swiper/css";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Images } from "./Utils";
import Popup from "../Popup";
import { MainBox } from "./DinnerStyled";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Sliders = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState(null);


  const handleClickOpen = (arrayData) => {
 
    setShowPopup(true);
    setData(arrayData);
  };

  

  return (
    <>
      <Box my="5%">
      <Carousel
      swipeable={true}
      draggable={true}
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={1000}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
      // deviceType={"desktop"}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
>
{Images.map((i, index) => {
            return (
              <MainBox sx={{ textAlign: "center" }} key={index}>
              <Box
                onClick={() => handleClickOpen(i.array)}
                sx={{ cursor: "pointer", width: {md: "80%",sm: "100%"} }}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    height: "250px",
                    borderRadius: "15px",
                  }}
                  src={i.img}
                  alt=""
                />
              </Box>
              <Box mt="3%">
                <Typography fontWeight="700">{i.place}</Typography>
              </Box>
              <Box>
                <Typography fontWeight="700">{i.date}</Typography>
              </Box>
            </MainBox>
              );
            })}
</Carousel>
        {showPopup ? (
          <Popup
            openPopup={showPopup}
            arrayData={data}
            setShowPopup={setShowPopup}
          />
        ) : null}
      </Box>
    </>
  );
};

export default Sliders;

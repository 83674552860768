import React from "react";
import { useForm } from "react-hook-form";
import { SubmitButton, TextFields, TextareaAutosizes } from "./ContactStyle";
import swal from "sweetalert";
import Fade from "react-reveal/Fade";
import emailjs from 'emailjs-com';

export default function Form() {
  const { register, handleSubmit } = useForm();

  const onSubmission = (data) => {
    emailjs.sendForm('service_ahetjyo', 'template_9r8gw9i', data.target, 'MXQpTFruEbU_s3x7M')
      .then((result) => {
        swal({
          title: "Request Submitted",
          text: "We'll get back to you soon.",
        }); 
      }, (error) => {
        swal({
          title: "There is  an error",
          text: error.text,
        })
      });
   
  };

  return (
    <>
      <form onSubmit={((e)=>{
        e.preventDefault()
        onSubmission(e);
      })}>
        <Fade left>
          <TextFields
            type="text"
            placeholder="Your Name"
            {...register("from_name")}
            fullWidth
            name='from_name'
            required
          />
        </Fade>

        <Fade right>
          <TextFields
            type="email"
            placeholder="Your Email"
            {...register("email")}
            fullWidth
            required
            name="email"
          />
        </Fade>

        <Fade left>
          <TextFields
            type="text"
            placeholder="Which Service Are You Interested In?"
            {...register("subject")}
            fullWidth
            required
            name="subject"
          />
        </Fade>

        <Fade right>
          <TextareaAutosizes
            minRows={10}
            {...register("message")}
            name="message"
            placeholder="Tell us a bit more about your project idea..."
            style={{
              maxWidth: "100%",
              width: "99%",
              border: "1px solid #C4C4C4",
              borderRadius: "3px",
            }}
          />
        </Fade>

        <Fade left>
          <SubmitButton type="submit">Send</SubmitButton>
        </Fade>
      </form>
    </>
  );
}

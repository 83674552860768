import August_pic1 from "../../../assets/Events/14 AUGUST/August_pic1.jpg";
import FareWell_pic1 from "../../../assets/Events/Farewell/FareWell_pic1.jpg";
import Wellcome_pic2 from "../../../assets/Events/Welcome/wellcome_pic2.jpg";
// import Event_pic1 from "../../../assets/Events/Welcome/wellcome_pic1.jpg";
import Event_pic5 from "../../../assets/Events/Welcome/wellcome_pic3.jpg";
import August_pic2 from "../../../assets/Events/14 AUGUST/company-events-2.jpeg";
import Event_pic3 from "../../../assets/Events/game/company-event-3.jpeg";
import FareWell_pic2 from "../../../assets/Events/Farewell/usman-farwell.jpeg";
import Aniversery4 from "../../../assets/Events/aniversery/aniversery4.png";
import Aniversery5 from "../../../assets/Events/aniversery/aniversery5.png";
import Aniversery6 from "../../../assets/Events/aniversery/aniversery7.png";
import games1 from "../../../assets/Events/game/company-event-3.jpeg";
import tour1  from "../../../assets/tours/tour23.png";
import tour2  from "../../../assets/tours/tour22.png";
import logo from '../../../assets/logo.png'

import { Wellcome,  FareWell, FourteenAugust, gamesArray, toursArray } from "./EventArray";
export const Images = [
  {
    id: 1,
    img: August_pic1,
    date: " 14 August 2022",
    event: "Let’s promote independence by promoting a Human Right Culture in which respect for human rights should be great.",
    array: FourteenAugust,
    logo:logo
  },
 
  {
    id: 2,
    img: FareWell_pic1,
    date: "QUH Dock",
    event: "Enjoy your next chapter! Don't hesitate to reach out if you need anything or just to catch up.",
    array: FareWell,
  },
  {
    id: 3,
    img: Wellcome_pic2,
    date:'QUH Dock',
    event: "Congratulations on being part of QUH Dock team! The entire office welcomes you, and we hope to have a long and successful journey together.",
    array: Wellcome,
  },
  {
    id: 4,
    img: games1,
    date: " 14 August 2023",
    event: "life is a dream for the wise, a game for the fool, a comedy for the rich, a tragedy for the poor",
    array: gamesArray,
    logo:logo
  },
  // {
  //   id: 5,
  //   img: tour1,
  //   date: "QUH Dock",
  //   event: "Let’s promote independence by promoting a Human Right Culture in which respect for human rights should be great.",
  //   array: toursArray,
  //   logo:logo
  // },
  // {
  //   id: 6,
  //   img: tour2,
  //   date: "QUH Dock",
  //   event: "Let’s promote independence by promoting a Human Right Culture in which respect for human rights should be great.",
  //   array: toursArray,
  //   logo:logo
  // },
  {
    id: 7,
    img: Event_pic5,
    date: "QUH Dock",
    event: "It's a welcomed gift to encourage others to achieve greatness.",
    array: Wellcome,
    logo:logo
  },
 
  {
    id: 8,
    img: Aniversery4,
    date: "QUH Dock",
    event: "Here’s to another year of success, growth, and happiness at the company. Cheers to many more!",
    array: Wellcome,
    logo:logo
  },
  {
    id: 9,
    img: Aniversery5,
    date: "QUH Dock",
    event: "A very happy anniversary to a valued member of the team. Your contributions have been a driving force behind our success",
    array: Wellcome,
    logo:logo
  },
  {
    id: 10,
    img: Aniversery6,
    date: "QUH Dock",
    event: "Your dedication and hard work have been a driving force behind our success. Happy anniversary and here’s to many more!",
    array: Wellcome,
    logo:logo
  },
  // {
  //     id:4,
  //     img:dining,
  //     date:'Date: 20th march 2022',
  //     event:'Event: Mango',
  //     array: Mango
  // }
];
